* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  height: 100%;
}

body {
  background-color: #fff;
  font-family: Helvetica, Arial;
  font-size: 3vmin;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.hidden {
  display: none;
}


.background {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-size: cover;
  background-position: center center;
  /*filter: blur(8em) opacity(0.6);*/
  position: absolute;
}

.main-wrapper {
  padding-top: 45px;
  align-items: center;
  display: flex;
  height: 100%;
  margin: 0 auto;
  justify-content: center;
  position: relative;
  width: 80%;
  z-index: 1;
}

.container {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
}

.main-container {
  flex: 1;
}

.now-playing__name {
  font-size: 1.5em;
  margin-bottom: 0.2em;
}

.now-playing__artist {
  margin-bottom: 1em;
}

.now-playing__status {
  margin-bottom: 1em;
}

.now-playing__cover {
  border-radius: 8px;
  float: left;
  margin-right: 10px;
  text-align: right;
  width: 300px;
  height: 300px;
}

.now-playing__side {
  margin-left: 2%;
  width: 45%;
}

.btn-spotify {
  background-color: #519C6F;
  border-radius:28px;
  border:0px solid #519C6F;
  display:inline-block;
  cursor:pointer;
  color:#ffffff;
  font-family:Arial;
  font-size:17px;
  padding:16px 31px;
  text-decoration:none;
}
.btn-spotify:active {
  position:relative;
  top:1px;
}

img { background: transparent; }

@font-face {
  font-family: 'Christmas Squad';
  src: local('Christmas Squad'), url(fonts/ChristmasSquad.otf) format('opentype');
}

/* MINE */
.header {
  color: #fff;
  position: absolute;
  background: #E03636;
  padding: 20px;
  width: 100%;
  text-align: center;
  font-size: 64px;
  top: 0;
  font-family: 'Christmas Squad';
}

.footer {
  color: #fff;
  position: absolute;
  bottom: 0;
  background: #E03636;
  padding: 20px;
  width: 100%;
  text-align: center;
  font-size: 12px;
}

.wrapper {
  margin-top: 175px;
  text-align: center;
}

.mode-wrapper {
  padding-top: 200px;
  font-size: 24px;
  color: #333;
}

.mode-buttons button {
  font-size: 18px !important;
  padding: 10px !important;
}

.scanBtn, .nextBtn, .restartBtn {
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

#embed-wrapper {
  margin: 0 20px;
  position: absolute;
  right: 100%;
}

.showPlayer {
  position: inherit !important;
}

#embed-overlay {
  background-color: #CBA95D;
  height: 250px;
  width: 90%;
  margin: auto;
  color: white;
  border-radius: 10px;
  padding: 20px;
  position: relative;
  box-sizing: border-box;
  min-width: 250px;
}

#embed-overlay.right {
  border: 5px solid #519C6F;
}

#embed-overlay.wrong {
  border: 5px solid #E03636;
}

.logo {
  margin-bottom: 50px;
}

#reveal-prompt {
  color: white;
  font-weight: bold;
  font-size: 24px;
  padding-top: 50px;
  text-align: center;
}

#game-over {
  font-size: 48px;
  font-family: 'Christmas Squad';
  padding-top: 40px;
}

#reveal-artist {
  font-size: 20px;
  padding: 10px;
}

#reveal-year {
  font-size: 72px;
  font-weight: bold;
  padding: 10px;
  font-family: 'Christmas Squad';
}

#reveal-track {
  font-size: 20px;
  font-style: italic;
  padding: 10px;
}

#log {
  position: fixed;
  bottom: 50px;
  right: 0;
  height: 200px;
  width: 200px;
  font-size: 10px;
  overflow-y: scroll;
  background-color: #ddd;
  text-align: left;
  padding: 4px;
}

.log-msg {
  padding: 4px;
  border-bottom: 1px solid #bbb;
}

.standalone .btn-wrapper {
  display: flex;
  padding: 20px;
  justify-content: center;
}

.standalone {
  padding-top: 25px;
  display: flex;
  justify-content: center;
}

.standalone .years {
  width: 100px;
  min-width: 100px;
  border-left: 1px solid silver;
}

.btn-player {
  padding: 10px !important;
  border-radius: 100px !important;
  font-size: inherit !important;
  height: 44px;
  margin: 10px;
}

.btn-wrapper .nextBtn {
  margin: 3px;
}

.flag {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

#gift-wrapper {
  padding-bottom: 20px;
}

.yr.arrow {
  margin-left: -40px;
  height: 15px;
}

.yr.arrow svg {
  margin-top: -5px;
}

.score {
  color: #519C6F;
  font-size: 48px;
  font-family: 'Christmas Squad';
  padding-bottom: 20px;
  font-weight: bold;
}

.yr {
  color: #333;
  font-size: 24px;
  font-family: 'Christmas Squad';
}

.main {
  flex-grow: 4; 
  max-width: 500px;
}

.charlie {
  width: 120px;
  border-radius: 10px;
}

.App-header .btn-spotify {
  margin-bottom: 20px;
}

.oldnew {
  font-size: 12px;
  color: #888;
  font-style: italic;
}

.loginDetails {
  font-size: 14px;
  color: #333;
  padding: 20px;
  font-style: italic;
}

.wrapper.card {
  max-width: 500px;
  margin: 175px auto;
}

#ad {
  font-size: 12px;
  max-width: 300px;
  margin: 25px auto;
  color: #333;
  font-style: italic;
}

.btn-spotify.disabled {
  background-color: #888 !important;
  border:0px solid #888 !important;
}